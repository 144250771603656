<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12" class="d-flex justify-start align-center">
        <v-select
          v-model="resourceType"
          :items="resourceTypes"
          item-text="name"
          item-value="value"
          :label="$t('resourceType.select')"
          dense
          @change="fetch"
        ></v-select>
        <div
          v-if="resourceType !== null"
          class="d-flex justify-center align-center pa-2"
        >
          <v-autocomplete
            style="max-height: 150px; overflow-y: auto; overflow-x: hidden"
            v-model="resource"
            :items="resources"
            item-text="name"
            item-value="uniqueIdentifier"
            return-object
            multiple
            :label="$t('resourceType.resource')"
            dense
            chips
            deletable-chips
            clearable
          ></v-autocomplete>
          <v-btn @click="selectAll" class="ml-2 align-self-center" small text>
            {{ $t("actions.resources.selectAll") }}
          </v-btn>
        </div>

        <div class="pa-2">
          <v-btn
            @click="addResource"
            :disabled="disabled"
            :color="disabled ? 'grey' : 'primary'"
          >
            {{ $t("resourceType.add") }}
          </v-btn>
        </div>
      </v-col>
    </v-row>

    <h3 class="title text-left" v-if="resourcesByType.length > 0">
      {{ $t("resourceType.selected") }}
    </h3>
    <v-row v-for="(r, i) in resourcesByType" :key="i + '-type'">
      <v-col cols="12" :md="2" :sm="3">
        <p class="overline">{{ getTypeName(r.resourceType) }}</p>
      </v-col>
      <v-col cols="12" :md="8" :sm="6" class="resource-chip-container">
        <v-chip
          class="pa-2 ma-1"
          v-for="c in r.resources"
          :key="c.identifier + c.resourceKey + r.type"
          >{{ c.name }}</v-chip
        >
      </v-col>
      <v-col cols="12" :md="2" :sm="3">
        <v-btn
          @click="setEditForResourceType(r)"
          color="primary"
          fab
          icon
          x-small
        >
          <v-icon>mdi-pen</v-icon>
        </v-btn>

        <v-btn @click="removeResourceType(r)" color="error" fab icon x-small>
          <v-icon>mdi-delete</v-icon>
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { ResourceTypes, ResourceTypeNames } from "@/_helpers/CsharpEnum.js";
import { mapActions } from "vuex";

export default {
  name: "ResourceSelector",

  props: {
    value: {
      type: Array,
      default: () => [],
    },

    includedResourceTypes: {
      type: Array,
      default: () => [],
      required: true,
    },
  },

  model: {
    prop: "value",
    event: "input",
  },

  data() {
    return {
      resources: [],
      resourceType: null,
      resource: null,
    };
  },

  computed: {
    disabled() {
      return this.resourceType === null || this.resource?.length === 0;
    },

    localValue: {
      get() {
        return this.value ?? [];
      },
      set(value) {
        this.$emit("input", value);
      },
    },

    singleTypes() {
      return [ResourceTypes.User, ResourceTypes.Company];
    },

    resourceTypes() {
      return ResourceTypeNames.map((name, index) => {
        return {
          name: name,
          value: index,
        };
      })
        .sort((d) => d.name)
        .filter((d) => this.includedResourceTypes.includes(d.value));
    },

    resourcesByType() {
      if (this.localValue.length === 0) return [];

      return this.localValue.reduce((acc, curr) => {
        let found = acc.find((d) => d.resourceType === curr.resourceType);
        if (!found) {
          acc.push({
            resourceType: curr.resourceType,
            resources: [{ ...curr }],
          });

          return acc;
        }

        found.resources.push({ ...curr });
        return acc;
      }, []);
    },
  },

  methods: {
    ...mapActions("reports", ["getResources"]),

    getTypeName(resourceType) {
      return ResourceTypeNames[resourceType];
    },

    removeResource(r) {
      this.localValue = this.localValue.filter(
        (d) =>
          d.resourceType !== r.resourceType &&
          d.identifier !== r.identifier &&
          d.resourceKey !== r.resourceKey
      );
    },

    removeResourceType(t) {
      this.localValue = this.localValue.filter(
        (d) => d.resourceType !== t.resourceType
      );
    },

    async setEditForResourceType(r) {
      if (r.resourceType === undefined) return;

      this.resourceType = r.resourceType;
      await this.fetch();

      // We map here because we need to match the object to the fetched resources (e.g. ignore the reportSoResourceId)
      this.resource = r.resources.map((d) => ({
        identifier: d.identifier,
        resourceKey: d.resourceKey,
        name: d.name,
        resourceType: r.resourceType,
        uniqueIdentifier: d.uniqueIdentifier,
      }));
    },

    addResource() {
      if (
        this.resourceType !== null &&
        this.resource !== null &&
        this.resource.length > 0
      ) {
        // Remove exiting resources for the resourceType that were adding
        let result = this.localValue;
        if (this.localValue !== null && this.localValue?.length > 0)
          result = result.filter((d) => d.resourceType !== this.resourceType);

        for (var r of this.resource) {
          result.push({
            ...r,
            resourceType: this.resourceType,
          });
        }

        this.localValue = result;
      }

      this.resourceType = null;
      this.resource = null;
      this.resources = [];
    },

    async fetch() {
      if (this.resourceType === null) return;

      this.resources = await this.getResources(this.resourceType);
    },

    selectAll() {
      if (
        this.resource !== null &&
        this.resource.length >= this.resources.length
      ) {
        this.resource = [];
        return;
      }

      this.resource = this.resources;
    },
  },

  async created() {
    await this.fetch();
  },

  watch: {},
};
</script>
<style>
.resource-chip-container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  overflow-x: hidden;
  overflow-y: scroll;
  max-height: 150px;
}
</style>
